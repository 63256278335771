define("ember-composable-helpers/helpers/slice", ["exports", "@ember/component/helper", "ember-composable-helpers/utils/as-array"], function (_exports, _helper, _asArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.slice = slice;

  function slice(_ref) {
    let [...args] = _ref;
    let array = args.pop();
    array = (0, _asArray.default)(array);
    return array.slice(...args);
  }

  var _default = (0, _helper.helper)(slice);

  _exports.default = _default;
});