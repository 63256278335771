define("liquid-fire/growable", ["exports", "@ember/service", "@ember/object/mixin", "@ember/string", "liquid-fire/promise", "velocity"], function (_exports, _service, _mixin, _string, _promise, _velocity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* jshint newcap: false */
  var _default = _mixin.default.create({
    growDuration: 250,
    growPixelsPerSecond: 200,
    growEasing: 'slide',
    shrinkDelay: 0,
    growDelay: 0,
    growWidth: true,
    growHeight: true,
    transitionMap: (0, _service.inject)('liquid-fire-transitions'),
    animateGrowth: function (elt, have, want) {
      this.get('transitionMap').incrementRunningTransitions();
      let adaptations = [];

      if (this.get('growWidth')) {
        adaptations.push(this._adaptDimension(elt, 'width', have, want));
      }

      if (this.get('growHeight')) {
        adaptations.push(this._adaptDimension(elt, 'height', have, want));
      }

      return _promise.default.all(adaptations).then(() => {
        this.get('transitionMap').decrementRunningTransitions();
      });
    },
    _adaptDimension: function (elt, dimension, have, want) {
      if (have[dimension] === want[dimension]) {
        return _promise.default.resolve();
      }

      let target = {};
      target['outer' + (0, _string.capitalize)(dimension)] = [want[dimension], have[dimension]];
      return (0, _velocity.default)(elt[0], target, {
        delay: this._delayFor(have[dimension], want[dimension]),
        duration: this._durationFor(have[dimension], want[dimension]),
        queue: false,
        easing: this.get('growEasing') || this.constructor.prototype.growEasing
      });
    },
    _delayFor: function (before, after) {
      if (before > after) {
        return this.get('shrinkDelay') || this.constructor.prototype.shrinkDelay;
      }

      return this.get('growDelay') || this.constructor.prototype.growDelay;
    },
    _durationFor: function (before, after) {
      return Math.min(this.get('growDuration') || this.constructor.prototype.growDuration, 1000 * Math.abs(before - after) / (this.get('growPixelsPerSecond') || this.constructor.prototype.growPixelsPerSecond));
    }
  });

  _exports.default = _default;
});