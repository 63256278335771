define("liquid-fire/components/liquid-container", ["exports", "@ember/component", "liquid-fire/growable", "liquid-fire/components/liquid-measured", "liquid-fire/templates/components/liquid-container", "jquery"], function (_exports, _component, _growable, _liquidMeasured, _liquidContainer, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_growable.default, {
    layout: _liquidContainer.default,
    classNames: ['liquid-container'],

    lockSize(elt, want) {
      elt.outerWidth(want.width);
      elt.outerHeight(want.height);
    },

    unlockSize() {
      let doUnlock = () => {
        this.updateAnimatingClass(false);

        if (this.element) {
          this.element.style.width = '';
          this.element.style.height = '';
        }
      };

      if (this._scaling) {
        this._scaling.then(doUnlock);
      } else {
        doUnlock();
      }
    },

    // We're doing this manually instead of via classNameBindings
    // because it depends on upward-data-flow, which generates warnings
    // under Glimmer.
    updateAnimatingClass(on) {
      if (this.isDestroyed) {
        return;
      }

      if (on) {
        this.element.classList.add('liquid-animating');
      } else {
        this.element.classList.remove('liquid-animating');
      }
    },

    didInsertElement() {
      this._super(...arguments);

      this._wasInserted = true;
    },

    actions: {
      willTransition(versions) {
        if (!this._wasInserted) {
          return;
        } // Remember our own size before anything changes


        let elt = (0, _jquery.default)(this.element);
        this._cachedSize = (0, _liquidMeasured.measure)(elt); // And make any children absolutely positioned with fixed sizes.

        for (let i = 0; i < versions.length; i++) {
          goAbsolute(versions[i]);
        }
      },

      afterChildInsertion(versions) {
        let elt = (0, _jquery.default)(this.element);
        let enableGrowth = this.get('enableGrowth') !== false; // Measure children

        let sizes = [];

        for (let i = 0; i < versions.length; i++) {
          if (versions[i].view) {
            let childElt = (0, _jquery.default)(versions[i].view.element);
            sizes[i] = (0, _liquidMeasured.measure)(childElt);
          }
        } // Measure ourself again to see how big the new children make
        // us.


        let want = (0, _liquidMeasured.measure)(elt);
        let have = this._cachedSize || want; // Make ourself absolute

        if (enableGrowth) {
          this.lockSize(elt, have);
        } else {
          this.lockSize(elt, {
            height: Math.max(want.height, have.height),
            width: Math.max(want.width, have.width)
          });
        } // Apply '.liquid-animating' to liquid-container allowing
        // any customizable CSS control while an animating is occuring


        this.updateAnimatingClass(true); // Make the children absolute and fixed size.

        for (let i = 0; i < versions.length; i++) {
          goAbsolute(versions[i], sizes[i]);
        } // Kick off our growth animation


        if (enableGrowth) {
          this._scaling = this.animateGrowth(elt, have, want);
        }
      },

      afterTransition(versions) {
        for (let i = 0; i < versions.length; i++) {
          goStatic(versions[i]);
        }

        this.unlockSize();
      }

    }
  });

  _exports.default = _default;

  function goAbsolute(version, size) {
    if (!version.view) {
      return;
    }

    let elt = (0, _jquery.default)(version.view.element);
    let pos = elt.position();

    if (!size) {
      size = (0, _liquidMeasured.measure)(elt);
    }

    elt.outerWidth(size.width);
    elt.outerHeight(size.height);
    elt.css({
      position: 'absolute',
      top: pos.top,
      left: pos.left
    });
  }

  function goStatic(version) {
    if (version.view && !version.view.isDestroyed) {
      let elt = (0, _jquery.default)(version.view.element);
      elt.css({
        width: '',
        height: '',
        position: ''
      });
    }
  }
});