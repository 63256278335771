define("liquid-fire/components/liquid-sync", ["exports", "@ember/component", "liquid-fire/templates/components/liquid-sync", "liquid-fire/mixins/pausable"], function (_exports, _component, _liquidSync, _pausable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_pausable.default, {
    tagName: '',
    layout: _liquidSync.default,

    didInsertElement() {
      this.pauseLiquidFire();
    },

    actions: {
      ready() {
        this.resumeLiquidFire();
      }

    }
  });

  _exports.default = _default;
});