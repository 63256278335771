define("@videoly/ember-trix-next/helpers/equal", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _helper.helper)(function equal(positional
  /*, named*/
  ) {
    return positional.reduce((a, b) => a === b);
  });

  _exports.default = _default;
});