define("ember-quill/initializers/init-quill-module", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function
    /* application */
  initialize() {
    (function () {
      /* globals define, Quill */
      function generateModule(name, values) {
        define(name, [], function () {
          'use strict';

          return values;
        });
      }

      generateModule('quill', {
        default: typeof Quill === 'undefined' ? null : Quill
      });
    })();
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});