define("ember-quill/components/quill-editor", ["exports", "quill", "@ember/component", "@ember/object", "@ember/application", "@ember/string", "ember-quill/templates/components/quill-editor"], function (_exports, _quill, _component, _object, _application, _string, _quillEditor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _quillEditor.default,
    editor: null,

    textChange() {},

    selectionChange() {},

    options: (0, _object.computed)(function () {
      return {
        theme: 'snow'
      };
    }),
    safeValue: (0, _object.computed)('value', function () {
      return (0, _string.htmlSafe)(this.value);
    }),
    fastboot: (0, _object.computed)(function () {
      return (0, _application.getOwner)(this).lookup('service:fastboot');
    }),

    didInsertElement() {
      this._super(...arguments); // Don't instantiate Quill if fastboot is detected


      if (this.get('fastboot.isFastBoot')) {
        return;
      }

      const editor = new _quill.default(this.element, this.options);
      editor.on('text-change', (delta, oldDelta, source) => {
        this.textChange(this.editor, delta, oldDelta, source);
      });
      editor.on('selection-change', (delta, oldDelta, source) => {
        this.selectionChange(this.editor, delta, oldDelta, source);
      });
      this.set('editor', editor);
    }

  });

  _exports.default = _default;
});