define("ember-composable-helpers/helpers/includes", ["exports", "@ember/array", "@ember/component/helper", "ember-composable-helpers/utils/as-array"], function (_exports, _array, _helper, _asArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.includes = includes;

  function includes(needleOrNeedles, haystack) {
    if (!(0, _array.isArray)(haystack)) {
      return false;
    }

    let needles = (0, _array.isArray)(needleOrNeedles) ? needleOrNeedles : [needleOrNeedles];
    let haystackAsEmberArray = (0, _array.A)((0, _asArray.default)(haystack));
    return (0, _asArray.default)(needles).every(needle => {
      return haystackAsEmberArray.includes(needle);
    });
  }

  var _default = (0, _helper.helper)(function (_ref) {
    let [needle, haystack] = _ref;
    return includes(needle, haystack);
  });

  _exports.default = _default;
});