define("ember-composable-helpers/helpers/previous", ["exports", "@ember/component/helper", "ember-composable-helpers/utils/get-index", "@ember/utils", "@ember/array", "ember-composable-helpers/-private/get-value-array-and-use-deep-equal-from-params"], function (_exports, _helper, _getIndex, _utils, _array, _getValueArrayAndUseDeepEqualFromParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.previous = previous;

  function previous(currentValue, array) {
    let useDeepEqual = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    let currentIndex = (0, _getIndex.default)(array, currentValue, useDeepEqual);

    if ((0, _utils.isEmpty)(currentIndex)) {
      return;
    }

    return currentIndex === 0 ? currentValue : (0, _array.A)(array).objectAt(currentIndex - 1);
  }

  var _default = (0, _helper.helper)(function (params) {
    let {
      currentValue,
      array,
      useDeepEqual
    } = (0, _getValueArrayAndUseDeepEqualFromParams.default)(params);
    return previous(currentValue, array, useDeepEqual);
  });

  _exports.default = _default;
});