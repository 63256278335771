define("ember-composable-helpers/helpers/toggle", ["exports", "@ember/component/helper", "@ember/object", "@ember/utils"], function (_exports, _helper, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.toggle = toggle;

  function nextIndex(length, currentIdx) {
    if (currentIdx === -1 || currentIdx + 1 === length) {
      return 0;
    }

    return currentIdx + 1;
  }

  function toggle(_ref) {
    let [prop, obj, ...values] = _ref;
    return function () {
      let currentValue = (0, _object.get)(obj, prop);

      if ((0, _utils.isPresent)(values)) {
        let currentIdx = values.indexOf(currentValue);
        let nextIdx = nextIndex(values.length, currentIdx);
        return (0, _object.set)(obj, prop, values[nextIdx]);
      }

      return (0, _object.set)(obj, prop, !currentValue);
    };
  }

  var _default = (0, _helper.helper)(toggle);

  _exports.default = _default;
});