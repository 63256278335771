define("ember-cli-stripe/services/stripe", ["exports", "@ember/service", "@ember/object", "@ember/polyfills", "@ember/object/internals", "@ember/utils", "@ember/application/deprecations", "ember-invoke-action", "rsvp", "ember-cli-stripe/utils/configuration-options"], function (_exports, _service, _object, _polyfills, _internals, _utils, _deprecations, _emberInvokeAction, _rsvp, _configurationOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global StripeCheckout */
  var _default = _service.default.extend({
    /*
     * Registers a component as the current target of this service.
     * When the first {{stripe-checkout}} component is registered we load
     * the StripeCheckout js lib.
     *
     * @public
     */
    registerComponent(component) {
      this._alive[(0, _internals.guidFor)(component)] = {
        component
      }; // Load StripeCheckout js lib

      this._loadStripeJs();
    },

    /**
     * Unregisters a component.
     *
     * @public
     */
    unregisterComponent(component) {
      delete this._alive[(0, _internals.guidFor)(component)];
    },

    /*
     * Open opens the StripeCheckout payment modal.
     *
     * @public
     */
    open(component) {
      this._stripeScriptPromise.then(() => {
        let config = this._stripeConfig(component);

        let stripeHandler = this._stripeHandler(component);

        stripeHandler.open(config);
      });
    },

    /*
     * Close closes the StripeCheckout payment modal.
     * @public
     */
    close(component) {
      let stripeHandler = this._stripeHandler(component);

      stripeHandler.close();
    },

    init() {
      this._super(...arguments);

      this._alive = {};
      this._scriptLoaded = false;
      this._scriptLoading = false;
    },

    /**
     * Looks for any Stripe config options on the component.
     */
    _componentStripeConfig(component) {
      let componentOptions = component.getProperties(_configurationOptions.default);
      return this._cleanupOptions(componentOptions);
    },

    /**
     * Stripe config options with env configs merged with the ones provided through
     * the component.
     */
    _stripeConfig(component) {
      let stripeConfig = (0, _object.getWithDefault)(this, 'stripeConfig', {});
      let options = {};

      if (_polyfills.assign) {
        (0, _polyfills.assign)(options, stripeConfig, this._componentStripeConfig(component));
      } else {
        // Support for Ember <= 2.4 (when assign was introduced)
        (0, _polyfills.merge)(options, stripeConfig);
        (0, _polyfills.merge)(options, this._componentStripeConfig(component));
      }

      return this._cleanupOptions(options);
    },

    _stripeHandler(component) {
      let componentGuid = (0, _internals.guidFor)(component);

      if ('handler' in this._alive[componentGuid]) {
        return this._alive[componentGuid]['handler'];
      }

      let stripeConfig = this._stripeConfig(component);

      if (!('key' in stripeConfig)) {
        throw new Error('[ember-cli-stripe] Missing required `key` param');
      }

      let handler = StripeCheckout.configure({
        key: stripeConfig.key,

        token() {
          (0, _emberInvokeAction.invokeAction)(component, 'onToken', ...arguments); // Add deprecation for previous `action` callback

          if (!(0, _utils.isBlank)(component.attrs.action)) {
            (true && !(false) && (0, _deprecations.deprecate)('Using `action` callback is deprecated and will be removed in future versions. Please use `onToken` with a closure action instead', false, {
              id: 'ember-cli-stripe.action-callback',
              until: '1.1.0'
            }));
            (0, _emberInvokeAction.invokeAction)(component, 'action', ...arguments);
          }
        },

        opened() {
          (0, _emberInvokeAction.invokeAction)(component, 'onOpened');
        },

        closed() {
          (0, _emberInvokeAction.invokeAction)(component, 'onClosed');
        }

      });
      this._alive[componentGuid]['handler'] = handler;
      return handler;
    },

    _loadStripeJs() {
      if (this._scriptLoaded || this._scriptLoading) {
        return this._stripeScriptPromise;
      }

      this._scriptLoading = true;
      let script = document.createElement('script');
      script.src = 'https://checkout.stripe.com/checkout.js';
      script.async = true;
      script.type = 'text/javascript';
      this._stripeScriptPromise = new _rsvp.default.Promise((resolve, reject) => {
        script.onload = () => {
          this._scriptLoaded = true;
          resolve();
          this.onScriptLoaded();
        };

        script.onerror = () => {
          this._invokeAction('onStripeLoadError', ...arguments);

          reject();
        };

        document.body.appendChild(script);
      });
      return this._stripeScriptPromise;
    },

    onScriptLoaded() {},

    onStripeLoadError() {},

    _cleanupOptions(options) {
      let cleanedOptions = {};

      for (let key in options) {
        if ((0, _utils.typeOf)(options[key]) !== 'undefined') {
          cleanedOptions[key] = options[key];
        }
      }

      return cleanedOptions;
    }

  });

  _exports.default = _default;
});