define("ember-quill/templates/components/quill-editor", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "J8tFFlZg",
    "block": "[[[1,[30,0,[\"safeValue\"]]],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "ember-quill/templates/components/quill-editor.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});