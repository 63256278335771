define("ember-simple-auth/utils/is-fastboot", ["exports", "@ember/debug", "@ember/application/instance"], function (_exports, _debug, _instance) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isFastBoot;

  /* eslint-disable no-unused-vars */
  // @ts-check
  function isFastBoot(owner) {
    (true && !(owner && typeof owner.lookup === 'function') && (0, _debug.assert)('You must pass in an owner to isFastBoot!', owner && typeof owner.lookup === 'function'));
    const fastboot = owner.lookup('service:fastboot');
    return fastboot ? fastboot.get('isFastBoot') : false;
  }
});